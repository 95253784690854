// Colors
$theme-on-primary: #fff;
$theme-primary: #0676e0;
$theme-secondary: #07a9f4;
$theme-on-secondary: #fff;
$theme-background-0dp: #fff;
$theme-on-background-0dp: #22262b;
$theme-background-1dp: #f6f6f6;
$theme-on-background-1dp: #22262b;
$theme-background-2dp: #e9e9ea;
$theme-on-background-2dp: #302f39;
$theme-background-3dp: #22262b;
$theme-on-background-3dp: #f6f6f6;
$theme-on-surface-0dp: #424242;
$theme-on-surface-1dp: #5f5f5f;
$theme-on-surface-2dp: #d8d8d8;
$theme-on-surface-3dp: #f7f7f7;
$theme-orange: #fa541c;
$theme-error: #f5222d;
$theme-success: #52c41a;
