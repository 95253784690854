@import 'theme';
@import 'config';
@import 'animations';

.app-layout {
  height: 100vh;

  .ant-layout-header {
    align-items: center;
    background: #fff;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
    display: flex;
    height: $driver-plus-navbar-height;
    justify-content: space-between;
    padding: 0 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .ant-btn-default {
    border-color: $theme-orange;
    color: $theme-orange;

    &:hover,
    &:focus {
      border-color: $theme-orange;
      color: $theme-orange;
    }
  }

  .app-content {
    background: #fff;
    margin-top: $driver-plus-navbar-height;
    overflow: auto;
    padding: 1.5rem;
  }
}

.navigation-drawer {
  .ant-drawer-body {
    padding: 0;
  }

  .unfold-icon-wrapper {
    padding: 0.625rem 0.625rem 0 0.625rem;
    text-align: right;
  }

  .organization-wrapper {
    align-items: center;
    border-bottom: 1px solid $theme-background-2dp;
    display: flex;
    padding: 0.625rem 1.5rem;
    text-align: left;

    svg {
      font-size: 1.125rem;
      margin-right: 0.625rem;
    }
  }

  .ant-menu-item {
    align-items: center;
    display: flex;

    svg {
      font-size: 1.125rem;
    }
  }
}

.text-primary {
  color: $theme-primary !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-align-left {
  text-align: left;
}

.font-light {
  font-weight: 300;
}

.icon {
  cursor: pointer;
  font-size: 1.125rem;
  transition: color 0.3s;

  &:hover {
    color: $theme-secondary;
  }
}

.ant-tabs-nav {
  display: flex !important;
  width: 100% !important;

  div:first-of-type {
    display: flex;
    text-align: center;
    width: 100%;
  }
}

.ant-tabs-tab {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.form-group {
  margin: 1.25rem 0;
  width: 100%;

  .ant-select,
  .ant-picker,
  .ant-picker-range,
  .ant-btn {
    width: 100%;
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}
